// 设置 rem 函数
function setRem() {
  const screenWidth = 1920;
  const scale = screenWidth / 192;
  let htmlWidth = document.documentElement.clientWidth || document.body.clientWidth;
  // 得到html的Dom元素
  const htmlDom = document.getElementsByTagName("html")[0];
  // 设置根元素字体大小
  if (htmlWidth > 1920) {
    htmlWidth = 1920;
  }
  htmlDom.style.fontSize = htmlWidth / scale + "px";
  // console.log(" htmlDom.style.fontSize", htmlDom.style.fontSize);
}
// 初始化
setRem();
// 改变窗口大小时重新设置 rem

window.addEventListener("resize", () => {
  setRem();
});

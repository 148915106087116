import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/less/reset.less";
import "@/utils/rem";
// import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
// Vue.use(ElementUI);

Vue.config.productionTip = false;

new Vue({
  // el: '#app',
  router,
  store,
  render: (h) => h(App)
}).$mount("#app");

<template>
  <div class="container">
    <div class="top">
      <div class="content">
        <div class="left">
          <div class="title1">MOZA</div>
          <div class="title2">自動顔検出ぼかしカメラ</div>
          <div class="title3">
            もっと気軽に、もっと自由に - 心配なしで、毎瞬間をシェア！
          </div>
          <div class="qr-box">
            <div class="qr-box-left">
              <img :src="qrCode" alt="" />
            </div>
            <div class="qr-box-right">
              <div class="top" @click="downLoad('GooglePlay')">
                <div class="qr-box-icon icon-google"></div>
                <div class="qr-right-title">Google Play</div>
              </div>
              <div class="bottom" @click="downLoad('AppStore')">
                <div class="qr-box-icon icon-apple"></div>
                <div class="qr-right-title">App Store</div>
              </div>
            </div>
          </div>

          <div class="title4">
            顔検出精度 98.15％
            <div class="comon-cell"></div>
          </div>
          <div class="common-title">顔認識精度 93.65%</div>
          <div class="common-title">顔検出時間 1秒以内</div>
          <div class="title5">
            上述のAIモデルのデータは、Googleマップで見つけた写真のテストセットにのみ適用されます
          </div>
        </div>
        <div class="right">
          <div class="left-line common-line"></div>
          <div class="center-swiper">
            <van-swipe :show-indicators="false" :autoplay="3000">
              <van-swipe-item v-for="(image, index) in images" :key="index">
                <img v-lazy="image.url" />
              </van-swipe-item>
            </van-swipe>
          </div>
          <div class="right-line common-line"></div>
        </div>
      </div>
    </div>

    <div class="footer">
      <div class="footer-top">
        <div class="footer-common-center">
          <div
            class="footer-left-title common-click footer-common-title"
            @click="goAgreement"
          >
            利用規約
          </div>
          <div
            class="footer-rihgt-title common-click footer-common-title"
            @click="goPrivacy"
          >
            プライバシーポリシー
          </div>
        </div>
      </div>
      <div class="footer-bottom">
        <div class="footer-common-center">
          <div class="footer-left-title footer-common-title">
            &#169;IFLYTEK(HONG KONG)COMPANY LIMITED
          </div>
          <div class="footer-rihgt-title footer-common-title">
            E-mail：support@moza.cam
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import { Swipe, SwipeItem, Lazyload } from 'vant';

Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Lazyload);
export default {
  name: "",
  data () {
    return {
      images: [
        {
          url: require('@/assets/img/1.jpg')
        },
        {
          url: require('@/assets/img/2.jpg')
        },
        {
          url: require('@/assets/img/3.jpg')
        },

      ],
      qrCode: require('@/assets/img/qr-code.png'),
      downLoadurl: {
        GooglePlay: 'https://play.google.com/store/apps/details?id=com.camera.moza',
        AppStore: 'https://apps.apple.com/jp/app/moza-%E8%87%AA%E5%8B%95%E7%9A%84%E9%A1%94%E9%9A%A0%E3%81%97-%E3%83%A2%E3%82%B6%E3%82%A4%E3%82%AF-%E3%81%BC%E3%81%8B%E3%81%97%E5%8A%A0%E5%B7%A5/id6475031416'
      }
    }
  },
  created () {

  },
  methods: {
    goPrivacy () {
      let url = window.location.href
      if (url.indexOf('#/') != -1) {
        url = url.replace('#/', 'privacyPolicy.html')
        window.open(url)
      } else {
        window.open('http://www.moza.cam/privacyPolicy.html')
      }
      // window.location.href = 'https://s1.voicecloud.cn/resources/mozaPrivacyPolicy/'
      // window.open('https://s1.voicecloud.cn/resources/mozaPrivacyPolicy/')

    },
    goAgreement () {
      let url = window.location.href
      if (url.indexOf('#/') != -1) {
        url = url.replace('#/', 'privacyAgreement.html')
        window.open(url)
      } else {
        window.open('http://www.moza.cam/privacyAgreement.html')
      }
      // window.location.href = 'https://s1.voicecloud.cn/resources/mozaPrivacyAgreement/'
      // window.open('https://s1.voicecloud.cn/resources/mozaPrivacyAgreement/')

    },

    downLoad (key) {
      window.location.href = this.downLoadurl[key]
    }

  },
}
</script>
<style lang="less" scoped>
.container {
  box-sizing: border-box;
  width: 100vw;
  position: relative;
  height: 100%;
  background-color: #fff;

  .top {
    background: #e9fbfe;
  }

  .content {
    display: flex;
    position: relative;
    width: 1600px;
    height: 990px;
    margin: 0 auto;

    .left {
      width: 1073px;
      // padding-left: 160px;
      padding-top: 166px;

      .title1 {
        position: relative;
        width: 299px;
        height: 100px;
        font-size: 100px;
        font-weight: 300;
        text-align: left;
        color: #222222;
        line-height: 138px;
        z-index: 1;
      }

      .title2 {
        width: 602px;
        height: 60px;
        font-size: 50px;
        font-weight: 400;
        text-align: left;
        color: #222222;
        line-height: 60px;
        margin-top: 18px;
      }

      .title3 {
        width: 913px;
        height: 42px;
        font-size: 32px;
        font-weight: 400;
        text-align: left;
        color: #222222;
        line-height: 42px;
        margin-top: 32px;
      }

      .qr-box {
        box-sizing: border-box;
        height: 120px;
        display: flex;
        margin-top: 50px;

        .qr-box-left {
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 120px;
          height: 120px;
          background: #ffffff;
          border-radius: 6px;
          margin-right: 10px;

          img {
            width: 104px;
            height: 104px;
          }
        }

        .qr-box-right {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .top,
          .bottom {
            box-sizing: border-box;
            display: flex;
            align-items: center;
            width: 165px;
            height: 55px;
            padding-left: 12px;
            background: #ffffff;
            border-radius: 6px;
            padding: 0 12px;
            cursor: pointer;

            .qr-box-icon {
              box-sizing: border-box;
              width: 40px;
              height: 40px;
              margin-right: 9px;
            }

            .icon-google {
              background: url("~@/assets/img/icon-google.png") no-repeat center;
              background-size: 100% 100%;
            }
            .icon-apple {
              background: url("~@/assets/img/icon-apple.png") no-repeat center;
              background-size: 100% 100%;
            }

            .qr-right-title {
              // width: 102px;
              height: 22px;
              font-size: 16px;
              font-weight: 400;
              text-align: left;
              color: #222222;
              line-height: 22px;
            }
          }
        }
      }

      .title4 {
        position: relative;
        width: 221px;
        height: 24px;
        font-size: 24px;
        font-weight: 400;
        text-align: center;
        color: #222222;
        line-height: 24px;
        margin-bottom: 27px;
        z-index: 9999999999;
        margin-top: 171px;
      }

      .comon-cell {
        position: absolute;
        left: 0;
        bottom: -4px;
        width: 221px;
        height: 18px;
        z-index: -1;
        background-color: rgba(0, 212, 255, 0.3);

        // background: url('~@/assets/img/line-bg.png');
      }

      .common-title {
        position: relative;
        width: 221px;
        height: 24px;
        font-size: 24px;
        font-weight: 400;
        text-align: center;
        color: #222222;
        line-height: 24px;
        margin-bottom: 27px;
        z-index: 999999;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          bottom: -4px;
          width: 221px;
          height: 18px;
          background-color: rgba(0, 212, 255, 0.3);
          z-index: -1;
        }
      }

      .title5 {
        position: relative;
        width: 759px;
        height: 18px;
        opacity: 0.8;
        font-size: 18px;
        font-weight: 400;
        text-align: left;
        color: rgba(34, 34, 34, 0.8);
        line-height: 18px;
        margin-top: 26px;
        z-index: 999999;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          bottom: -3px;
          width: 729px;
          height: 6px;
          z-index: -1;

          background-color: rgba(0, 212, 255, 0.3);
        }
      }
    }

    .right {
      box-sizing: border-box;
      position: relative;
      display: flex;
      padding-top: 127px;
      padding-left: 189px;

      .common-line {
        width: 3px;
        height: 347px;
      }

      .left-line {
        position: relative;
        top: 39px;
        background: url("~@/assets/img/left-line.png") no-repeat center;
        background-size: 100% 100%;
      }
      .right-line {
        position: relative;
        top: 352px;
        background: url("~@/assets/img/right-line.png") no-repeat center;
        background-size: 100% 100%;
      }

      .center-swiper {
        width: 352px;
        height: 761px;
        border-radius: 15px;
        margin: 0 31px 0 39px;
        width: 358px;
        height: 767px;
        padding: 3px 3px;
        background: url("~@/assets/img/swiper-bg.png") no-repeat center;
        background-size: 100% 100%;
        overflow: hidden;

        /deep/.van-swipe__indicators {
          bottom: 100px;

          .van-swipe__indicator {
            width: 6px;
            height: 6px;
            opacity: 0.5;
            background: #cddbde;
            border-radius: 50%;
          }

          .van-swipe__indicator--active {
            width: 23px;
            height: 6px;
            border-radius: 3px;
          }
        }

        /deep/.van-swipe {
          border-radius: 15px;
          overflow: hidden;

          .van-swipe-item {
            border-radius: 15px;
            overflow: hidden;
          }
        }
        img {
          width: 352px;
          height: 761px;
          border-radius: 15px;
        }
      }
    }
  }

  .footer {
    box-sizing: border-box;
    width: 100%;
    height: 130px;
    padding-top: 34px;

    .common-click {
      cursor: pointer;
    }

    .footer-common-center {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 20px;
      width: 1px;
      background: #222222;
      margin: 0 20px;
    }

    .footer-common-title {
      height: 20px;
      font-size: 20px;
      font-weight: 400;
      text-align: left;
      color: #222222;
      line-height: 20px;
    }

    .footer-left-title {
      position: absolute;
      top: 0;
      right: 20px;
      text-align: right;
      width: 400px;
    }
    .footer-rihgt-title {
      position: absolute;
      top: 0;
      left: 20px;
      text-align: left;
      width: 300px;
    }

    .footer-top {
      position: relative;
      height: 20px;
      margin-bottom: 20px;
    }
    .footer-bottom {
      position: relative;
      height: 20px;
    }
  }
}
</style>
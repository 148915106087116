<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { pageTitle } from "@/config.js";

export default {
  created () {
    document.title = pageTitle
  }
};
</script>
<style lang="less">
#app {
overflow: hidden;
overflow-y: scroll;
  height: 100vh;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}
</style>
